import { Link, useNavigate } from "react-router-dom";
import ArrowBackBlack from "assets/back-arrow-black.svg";
import ArrowBackWhite from "assets/back-arrow-white.svg";
import Settings from "assets/settings.svg";
import Flixs from "assets/logo.png";

const STYLES = [
  "header-secondary",
  "header-secondary-settings",
  "header-secondary-inverted",
];

const HeaderSecondary = ({ headerStyles, children, redirect }) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.setItem('access_token', []);
    navigate('/login');
  }

  function toggleLogout() {
    const logout = document.querySelector(".dropdownContent");
    logout.classList.toggle("invisible");
  }

  switch (headerStyles) {
    case STYLES[0]:
      return (
        <header
          className={`w-full grid grid-cols-3 items-center bg-scienceBlue px-4 py-3`}
        >
          <div className="logo justify-self-start">
            <Link to={redirect}>
              <img src={ArrowBackWhite} alt="back" width={25} />
            </Link>
          </div>

          <div className="title justify-self-center whitespace-nowrap">
            <span className="text-white block text-base font-bold">
              {children}
            </span>
          </div>
        </header>
      );

    case STYLES[1]:
      return (
        <header className={`w-full bg-scienceBlue px-4 py-3`}>
          <div className="title whitespace-nowrap text-center">
            <span className="text-white text-base font-bold">{children}</span>
          </div>

          <div className="logo float-right -mt-6">
            <button type="button" id="dropdownButton" onClick={toggleLogout}>
              <img src={Settings} alt="back" width={25} />
            </button>

            <div className="dropdownContent bg-white dark:bg-black dark:text-white p-3 rounded-md shadow-md absolute -ml-12 transition duration-300 invisible">
              <button onClick={logout}>
                <span className="text-sm font-medium hover:text-scienceBlue transition">
                  Logout
                </span>
              </button>
            </div>
          </div>
        </header>
      );

    case STYLES[2]:
      return (
        <header
          className={`w-full grid grid-cols-3 items-center bg-white px-4 py-3`}
        >
          <div className="logo justify-self-start">
            <Link to={redirect}>
              <img src={ArrowBackBlack} alt="back" width={25} />
            </Link>
          </div>

          <div className="title justify-self-center whitespace-nowrap">
            <img src={Flixs} alt="Flixs" width={105} />
          </div>
        </header>
      );

    default:
      return null;
  }
};

export default HeaderSecondary;
