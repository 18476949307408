const STYLES = [
  "btn-primary",
  "btn-primary-inverted",
  "btn-secondary",
  "btn-secondary-inverted",
];

const SIZES = ["btn-full", "btn-half", "btn-very-small"];

const Button = ({ children, type, btnStyle, btnSize, className, disabled, style }) => {
  const checkBtnStyle = STYLES.includes(btnStyle) ? btnStyle : STYLES[0];
  const checkBtnSize = SIZES.includes(btnSize) ? btnSize : SIZES[0];

  return (
    <button
      className={`${checkBtnStyle} ${checkBtnSize} ${className}`}
      type={type}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
};

export default Button;
