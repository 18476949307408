import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";

import NavHome from "components/Nav/NavHome";
import Footer from "./Footer";
import Button from "components/Button";

const Produk = ({ className }) => {
  let params = useParams();

  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState([]);
  const [background, setBackground] = useState({});
  const [images, setImages] = useState([]);
  const [profileWeb, setProfileWeb] = useState([]);
  const htmlTag = document.querySelector("html");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", params.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://project.inagata.com/flix/public/api/index-detail-produk",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        setLoading(true);
        setProfileWeb(result.data.Profile_web);
        setCard(result.data.Menu2);
        setBackground(result.data.Menu2.reverse()[0].background2);
        setImages(result.data.Multiple_menu_2);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} min-h-[100vh] bg-white dark:bg-black`}
    >
      {loading === false
        ? null
        : profileWeb.tema === "gelap"
        ? htmlTag.classList.add("dark")
        : htmlTag.classList.add("light")}

      {/* contents */}
      <div className="h-[400px]" style={{ backgroundImage: `${background}` }}>
        <Link
          to={`${loading === false ? null : profileWeb.set_path_web}`}
          className="flex gap-2 items-center justify-center py-3"
        >
          <img
            src={loading === false ? null : profileWeb.ikon}
            alt="car-icon"
            width={50}
          />
          <h3 className="font-bold text-white">
            {loading === false ? null : profileWeb.nama_web}
          </h3>
        </Link>
      </div>

      <div className="px-5 pb-5 pt-5 -mt-12 rounded-t-[36px] bg-white dark:bg-black">
        <h3 className="font-bold text-center dark:text-white">Produk</h3>

        {/* cars */}
        <div className="grid grid-cols-1 gap-4 my-5">
          {loading === false ? null : card.length === 0 ? (
            <h3 className="font-bold text-center dark:text-white">
              Belum Ada Produk
            </h3>
          ) : (
            card.map((item) => {
              return (
                <>
                  <div
                    style={{ backgroundImage: `${item.background2}` }}
                    className="h-[150px] rounded-xl bg-cover bg-center"
                  >
                    <div className="flex items-center justify-between px-4 mt-[100px] relative">
                      <div
                        className="absolute h-[150px] w-full left-0 rounded-xl -bottom-[22px]"
                        style={{
                          background:
                            "linear-gradient(to top, rgba(0, 0, 0, 0.0), rgba(255,0,0,0) 50%)",
                        }}
                      ></div>
                      <h3 className="font-semibold text-white text-sm z-10">
                        {item.keterangan2}
                      </h3>

                      <Link
                        to={`/${params.name}/detail-produk`}
                        className="z-10"
                      >
                        <button
                          type="button"
                          className="bg-white font-semibold text-sm text-scienceBlue rounded-xl py-1 px-6"
                        >
                          Detail
                        </button>
                      </Link>
                    </div>
                  </div>
                </>
              );
            })
          )}
        </div>

        {/* footer */}
        <Footer />
      </div>

      {/* Navigation */}
      <NavHome className={`text-sm p-6`} />

      {/* Buttons */}
      <div className="px-5 pb-5 pt-3 dark:bg-[#282828]">
        <Link to={"/"}>
          <Button type={"button"}>Simpan dan Publish</Button>
        </Link>

        <Link to={"/settings"}>
          <Button
            type={"button"}
            btnStyle={"btn-primary-inverted"}
            className={"mt-4"}
          >
            Kembali Edit
          </Button>
        </Link>
      </div>
    </motion.div>
  );
};

export default Produk;
