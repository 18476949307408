import { Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "pages/Home";
import Login from "pages/UserGate/Login";
import ForgotPassword from "pages/UserGate/ForgotPassword";
import Registrasi from "pages/UserGate/Registrasi";
import Tentang from "pages/Tentang";
import WebHome from "pages/Web/Home";
import WebProduk from "pages/Web/Produk";
import WebDetailProduk from "pages/Web/DetailProduk";
import WebInfo from "pages/Web/Info";
import WebGaleri from "pages/Web/Galeri";
import WebDetailArtikel from "pages/Web/DetailArtikel";

import SettingProfil from "pages/Settings/SettingProfil";
import SettingBeranda from "pages/Settings/SettingBeranda";

const AnimatedRoutes = () => {
  // const location = useLocation();

  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Home className={"min-h-[100vh]"} />} />
        <Route path="/tentang" element={<Tentang className={"min-h-[100vh]"} />} />

        <Route
          path="/registrasi"
          element={<Registrasi className={"min-h-[100vh]"} />}
        />
        <Route path="/login" element={<Login className={"min-h-[100vh]"} />} />
        <Route
          path="/forgot"
          element={<ForgotPassword className={"min-h-[100vh]"} />}
        />

        <Route path="/settings" element={<SettingProfil />} />
        <Route path="/settings/home" element={<SettingBeranda />} />

        <Route path=":name/home" element={<WebHome />} />
        <Route path=":name/produk" element={<WebProduk />} />
        <Route path=":name/detail-produk" element={<WebDetailProduk />} />
        <Route path=":name/info" element={<WebInfo />} />
        <Route path=":name/galeri" element={<WebGaleri />} />
        <Route path=":name/detail-artikel" element={<WebDetailArtikel />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
