import Flixs from "assets/logo.png";
import Email from "assets/email.svg";
import Eye from "assets/eye.svg";
import Key from "assets/key.svg";
import "pages/UserGate/UserGate.css";

import Button from "components/Button";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Login = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    localStorage.setItem("access_token", []);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://project.inagata.com/flix/public/api/auth/login",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.error === "Unauthorized") {
          alert("Username atau Password salah");
          setLoading(false);
        } else {
          localStorage.setItem("access_token", JSON.stringify(result));
          navigate("/settings");
          setLoading(false);
        }
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  function showHide() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} bg-white`}
    >
      <form
        className="px-7 py-5 grid gap-4 place-items-end h-full"
        onSubmit={handleSubmit}
      >
        <div className="w-full">
          <img src={Flixs} alt="Flixs" className="mx-auto mb-5" />

          <div className="inputWithIcon inputIconBg">
            <input
              className="user-gate"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <img src={Email} alt="Email" className="icon-left" />
          </div>

          <div className="inputWithIcon inputIconBg">
            <input
              className="user-gate"
              type="password"
              id="password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img src={Key} alt="Key" className="icon-left" />
            <img
              src={Eye}
              alt="show/hide"
              className="icon-right"
              onClick={showHide}
            />
          </div>

          <Link to={"/forgot"}>
            <span className="text-scienceBlue text-[10px] font-normal float-right mb-8">
              Lupa Password?
            </span>
          </Link>

          {/* <Link to={"/settings"}> */}
          <Button
            type={"submit"}
            btnStyle={"btn-primary"}
            className={
              "disabled:transform-none disabled:transition-none disabled:bg-scienceBlue disabled:text-white disabled:cursor-not-allowed"
            }
            disabled={loading === true ? true : false}
          >
            {loading === true ? (
              <div className="flex justify-center align-center">
                <svg
                  className="motion-reduce:hidden animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              "Masuk"
            )}
          </Button>
          {/* </Link> */}
        </div>

        <div className="text-center w-full">
          <span className="text-[11px] font-normal">
            Belum punya akun?&nbsp;
            <Link
              to={"/registrasi"}
              className="text-scienceBlue font-bold inline"
            >
              Register
            </Link>
          </span>
        </div>
      </form>
    </motion.div>
  );
};

export default Login;
