import Flixs from "assets/logo.png";
import HeaderSecondary from "components/Header/HeaderSecondary";

import { motion } from "framer-motion";

const Tentang = ({ className }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} bg-white`}
    >
      <HeaderSecondary
        headerStyles={"header-secondary"}
        children={"Tentang Kami"}
        redirect={"/"}
      />

      <div className="px-7 py-5 my-24">
        <img src={Flixs} alt="Flixs" className="mb-7 mx-auto" />
        <p className="text-[#838282] text-sm text-justify leading-5 font-normal indent-10">
          Kami sudah berpengalaman menangani berbagai macam klien, baik
          perorangan maupun perusahaan, dalam berbagai macam bidang bisnis, dan
          di seluruh penjuru Indonesia.
        </p>
        <br />
        <p className="text-[#838282] text-sm text-justify leading-5 font-normal indent-10">
          Mulailah berinvestasi di website profesional yang menggambarkan citra
          baik untuk bisnis anda. Kami pastikan website anda akan terlihat
          sangat profesional dan modern, sehingga pengunjung akan merasa yakin
          untuk memilih produk/atau jasa yang anda tawarkan.
        </p>
      </div>
    </motion.div>
  );
};

export default Tentang;
