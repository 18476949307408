import { Link } from "react-router-dom";
import Flixs from "assets/logo.png";

const HeaderPrimary = ({ className }) => {
  return (
    <header className={`${className} flex items-center justify-between`}>
      <div className="logo">
        <img src={Flixs} alt="Flixs" width={121} />
      </div>

      <Link to={"/tentang"}>
        <span className="text-[12px] font-normal text-scienceBlue">
          Tentang Kami
        </span>
      </Link>
    </header>
  );
};

export default HeaderPrimary;
