import { React, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";

import BackArrowBlack from "assets/back-arrow-black.svg";

import Button from "components/Button";

const DetailArtikel = ({ className }) => {
  const param = useParams();
  const htmlTag = document.querySelector("html");

  const [loading, setLoading] = useState(false);
  const [profileWeb, setProfileWeb] = useState([]);
  const [judul, setJudul] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [lokasi, setLokasi] = useState("");
  const [gambar, setGambar] = useState([]);

  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", param.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://project.inagata.com/flix/public/api/index-info",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        setLoading(true);
        setProfileWeb(result.data.Profile_web);
        setJudul(result.data.Menu_info.reverse()[0].judul);
        setDeskripsi(result.data.Menu_info.reverse()[0].deskripsi);
        setLokasi(result.data.Menu_info.reverse()[0].lokasi);
        setGambar(result.data.Multiple_info.reverse()[0]);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} min-h-[100vh] bg-white dark:bg-black`}
    >
      {loading === false
        ? null
        : profileWeb.tema === "gelap"
        ? htmlTag.classList.add("dark")
        : htmlTag.classList.add("light")}
      {/* contents */}
      <div className="px-5 pb-5 pt-1">
        <div className="my-3 flex gap-2 items-center justify-center relative">
          <img src={profileWeb.ikon} alt="icon" width={50} />
          <h3 className="font-bold dark:text-white">{profileWeb.nama_web}</h3>
          <Link to={`/${param.name}/info`} className="absolute left-0">
            <img src={BackArrowBlack} alt="Back" className="dark:car-dark" />
          </Link>
        </div>

        {/* pictures */}
        <div className="mt-10">
          <img
            src={gambar.gambar}
            alt="img-view"
            className="w-full mb-3 rounded-2xl"
          />

          <h1 className="font-bold text-xl mt-6 dark:text-white">{judul}</h1>

          <p className="mt-4 text-xs text-justify dark:text-white">
            <b>{lokasi} -</b> {deskripsi}
          </p>
        </div>

        {/* footer */}
        <div>
          <hr className="bg-scienceBlue h-[2px] mt-7 mb-5 dark:bg-[#f7c600]" />

          <p className="font-normal text-[11px] text-center dark:text-white">
            Copyright © 2022. Made by Flixs.
          </p>
        </div>
      </div>

      {/* Buttons */}
      <div className="px-5 pb-5 pt-3 dark:bg-[#282828]">
        <Link to={"/"}>
          <Button type={"button"}>Simpan dan Publish</Button>
        </Link>

        <Link to={"/settings"}>
          <Button
            type={"button"}
            btnStyle={"btn-primary-inverted"}
            className={"mt-4"}
          >
            Kembali Edit
          </Button>
        </Link>
      </div>
    </motion.div>
  );
};

export default DetailArtikel;
