import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";

import NavHome from "components/Nav/NavHome";
import Footer from "./Footer";
import Button from "components/Button";

const Galeri = ({ className }) => {
  let params = useParams();

  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState([]);
  const [profileWeb, setProfileWeb] = useState([]);
  const htmlTag = document.querySelector("html");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", params.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://project.inagata.com/flix/public/api/index-menu5",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        setLoading(true);
        setProfileWeb(result.data.Profile_web);
        setCard(result.data.Multiple_menu_5);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  // console.log(link, "link");
  console.log(profileWeb, "profile");
  console.log(card, "cards");
  // console.log(header, "header");

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} min-h-[100vh] bg-white dark:bg-black`}
    >
      {loading === false
        ? null
        : profileWeb.tema === "gelap"
        ? htmlTag.classList.add("dark")
        : htmlTag.classList.add("light")}

      {/* contents */}
      <div className="px-5 pb-5 pt-1">
        <Link
          to={`${loading === false ? null : profileWeb.set_path_web}`}
          className="my-3 flex gap-2 items-center justify-center"
        >
          <img
            src={loading === false ? null : profileWeb.ikon}
            alt="icon"
            className="w-[52.59px]"
          />
          <h3 className="font-bold dark:text-white">
            {loading === false ? null : profileWeb.nama_web}
          </h3>
        </Link>

        {/* cars */}
        <div className="grid grid-cols-1 gap-4 mt-10 mb-5">
          {loading === false
            ? null
            : card.map((item) => {
                return (
                  <div
                    style={{ backgroundImage: `${item.file_name5}` }}
                    className="h-[150px] rounded-xl bg-cover bg-center relative"
                  >
                    <div
                      className="absolute h-[150px] w-full left-0 rounded-xl -bottom-[22px]"
                      style={{
                        background:
                          "linear-gradient(to top, rgba(0, 0, 0, 0.0), rgba(255,0,0,0) 50%)",
                      }}
                    ></div>
                  </div>
                );
              })}
        </div>

        {/* footer */}
        <Footer />
      </div>

      {/* Navigation */}
      <NavHome className={`text-sm p-6`} />

      {/* Buttons */}
      <div className="px-5 pb-5 pt-3 dark:bg-[#282828]">
        <Link to={"/"}>
          <Button type={"button"}>Simpan dan Publish</Button>
        </Link>

        <Link to={"/settings"}>
          <Button
            type={"button"}
            btnStyle={"btn-primary-inverted"}
            className={"mt-4"}
          >
            Kembali Edit
          </Button>
        </Link>
      </div>
    </motion.div>
  );
};

export default Galeri;
