import { useState } from "react";
import HeaderSecondary from "components/Header/HeaderSecondary";
import Forgot from "assets/forgot-password.png";
import Eye from "assets/eye.svg";
import Key from "assets/key.svg";
import Email from "assets/email.svg";
import "pages/UserGate/UserGate.css";

import Button from "components/Button";

import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const ForgotPassword = ({ className }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const getToken = JSON.parse(localStorage.getItem("access_token"));
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer Bearer ${getToken.access_token}`);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://project.inagata.com/flix/public/api/auth/email/verification-notification?email=${email}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "Berhasil") {
          navigate("/login");
          setLoading(false);
        }
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  // function showHide() {
  //   var x = document.getElementById("password");
  //   if (x.type === "password") {
  //     x.type = "text";
  //   } else {
  //     x.type = "password";
  //   }
  // }

  // const handleSubmit = () => {
  //   const getToken = JSON.parse(localStorage.getItem("access_token"));
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer Bearer ${getToken.access_token}`);

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `https://project.inagata.com/flix/public/api/auth/email/verification-notification?email=${email}`,
  //     requestOptions
  //   )
  //     .then((res) => res.json())
  //     .then((result) => console.log(result))
  //     .catch((error) => console.log("error", error));
  // };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} bg-white`}
    >
      <HeaderSecondary
        headerStyles={"header-secondary-inverted"}
        redirect={"/"}
      />

      <form className="px-7 py-5 my-5" onSubmit={handleSubmit}>
        <img src={Forgot} alt="Lupa Password" className="mb-7 mx-auto" />
        <h3 className="font-bold text-2xl mb-1">Ganti Password</h3>

        <>
          <div className="inputWithIcon inputIconBg">
            <input
              className="user-gate"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <img src={Email} alt="Email" className="icon-left" />
          </div>
          {/* <div class="inputWithIcon inputIconBg">
            <input
              className="user-gate"
              type="password"
              id="passwordNew"
              placeholder="Password Lama"
            />
            <img src={Key} alt="Key" className="icon-left" />
          </div>

          <div class="inputWithIcon inputIconBg">
            <input
              className="user-gate"
              type="password"
              id="passwordNew"
              placeholder="Password Baru"
            />
            <img src={Key} alt="Key" className="icon-left" />
            <img
              src={Eye}
              alt="show/hide"
              className="icon-right"
              onClick={showHide}
            />
          </div>

          <div class="inputWithIcon inputIconBg">
            <input
              className="user-gate"
              type="password"
              id="passwordRepeat"
              placeholder="Ulangi Password Baru"
            />
            <img src={Key} alt="Key" className="icon-left" />
            <img
              src={Eye}
              alt="show/hide"
              className="icon-right"
              onClick={showHide}
            />
          </div> */}
          <Button
            type={"submit"}
            btnStyle={"btn-primary"}
            className={
              "disabled:transform-none disabled:transition-none disabled:bg-scienceBlue disabled:text-white disabled:cursor-not-allowed"
            }
            disabled={loading === true ? true : false}
          >
            {loading === true ? (
              <div className="flex justify-center align-center">
                <svg
                  class="motion-reduce:hidden animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              "Masuk"
            )}
          </Button>
        </>
      </form>
    </motion.div>
  );
};

export default ForgotPassword;
