import {useState} from 'react';
import HeaderSecondary from "components/Header/HeaderSecondary";

import "pages/Settings/settings.css";

import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const forbiddenLink = ['login', 'register', 'home', 'settings', 'settings/home', 'tentang', 'produk', 'info', 'galeri', 'detail-artikel', 'forgot']

const SettingProfil = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState()
  const [pathWeb, setPathWeb] = useState('');
  const [namaWeb, setNamaWeb] = useState('');
  const [icon, setIcon] = useState(null);

  const navigate = useNavigate();

  const data = {
    ikon: [icon],
    set_path_web: pathWeb,
    nama_web: namaWeb
  };
  const getToken = JSON.parse(localStorage.getItem('access_token'));

  console.log(data);
  const html = document.querySelector("html");

  function handleChange(e) {
    if (e.target.checked) {
      html.classList.add("dark");
    } else {
      html.classList.remove("dark");
    }
  }

  const postData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer Bearer ${getToken.access_token}`);

    var formdata = new FormData();
    formdata.append("ikon", data.ikon[0], data.ikon[0].name);
    formdata.append("set_path_web", data.set_path_web);
    formdata.append("nama_web", data.nama_web);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://project.inagata.com/flix/public/api/beranda", requestOptions)
      .then(response => response.text())
      .then(result => {
        setLoading(false);
        setResponse(result);
        console.log(JSON.parse(response));
        if(JSON.parse(response).message === "Success"){
          navigate('/settings/home');  
        }
      })
      .catch(error => console.log('error', error));
  }

  const handleSubmit = () => {
    // localStorage.setItem('setting_beranda', JSON.stringify(data));
    postData();
    if(response.message === "Success"){
      navigate('/settings/home');  
    }
    // navigate('/settings/home');
  }
  // console.log(data.ikon.file.name)

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} bg-white dark:bg-black`}
    >
      <HeaderSecondary
        headerStyles={"header-secondary-settings"}
        children={"Setting Profil Website"}
        redirect={null}
      />

      <div className="px-5 py-4 flex flex-col justify-between min-h-[100vh] dark:text-white">
        <div>
          <div className='border-b-[1px]'>
            <div className="py-3 flex items-center justify-between">
              <p className="inline">
                <b>Icon</b> (Size 512x512)
              </p>

              <input type="file" id="upload" onChange={event => setIcon(event.target.files[0])} hidden />
              <label htmlFor="upload" className="upload dark:upload-dark">
                Upload Gambar
              </label>
            </div>
            <small className='dark:text-white'>{icon ? icon.name : null}</small>
          </div>

          <div className="border-b-[1px] py-4 flex items-center justify-between">
            <p className="inline">
              <b>Gunakan Tema Dark Mode</b>
            </p>

            {/* toogle-dark-mode */}
            <label
              htmlFor="default-toggle"
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="checkbox"
                id="default-toggle"
                className="sr-only peer"
                onChange={(e) => handleChange(e)}
              />

              <div className="w-[52px] h-7 bg-gray-100 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-scienceBlue peer-checked:after:translate-x-full peer-checked:after:border-scienceBlue peer-checked:after:bg-scienceBlue after:content-[''] after:absolute after:top-[2px] after:left-[2px]  after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-[#CFD9E5]"></div>
            </label>
          </div>

          <div className="border-b-[1px] py-3">
            <label htmlFor="address" className="block text-md mb-1 font-bold">
              Link Web Address
            </label>

            <div className="flex gap-2">
              <input
                type="text"
                id="domain"
                className="bg-[#CFD9E5] w-[95px] border border-gray-300 text-gray-700 text-sm rounded-[16px] focus:ring-scienceBlue py-3 px-4 dark:bg-[#CFD9E5]dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-scienceBlue"
                value={"flix.com/"}
                readOnly={true}
              />
              <input
                type="text"
                id="address"
                className="bg-white w-full border border-gray-300 text-gray-700 text-sm rounded-[16px] focus:ring-scienceBlue py-3 px-4 dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                placeholder="Masukkan Link Web Address"
                value={pathWeb}
                onChange={e => setPathWeb(e.target.value)}
                required
              />
            </div>
            <p className='text-red-500'>
              {forbiddenLink.includes(pathWeb) ? 'Link web address sudah dipakai' : null}
            </p>
          </div>

          <div className="py-3">
            <label htmlFor="address" className="block text-md mb-1 font-bold">
              Nama Website
            </label>

            <input
              type="text"
              id="address"
              className="bg-white border border-gray-300 text-gray-700 text-sm rounded-[16px] focus:ring-scienceBlue block w-full py-3 px-4 dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
              placeholder="Masukkan Nama Website"
              required
              value={namaWeb}
              onChange={e => setNamaWeb(e.target.value)}
            />
          </div>
        </div>

        <div>
          <button onClick={handleSubmit} className="btn-primary btn-full disabled:transform-none disabled:transition-none disabled:bg-scienceBlue disabled:text-white disabled:cursor-not-allowed" disabled={loading === true ? true : false}>
          {
              loading === true ? (
                <div className="flex justify-center align-center">
                  <svg className="motion-reduce:hidden animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              ) : (
                'Lanjut'
              )
            }
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default SettingProfil;
