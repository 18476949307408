import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderSecondary from "components/Header/HeaderSecondary";
import "pages/Settings/settings.css";

import Add from "assets/add.svg";
import Pencil from "assets/pencil.svg";
import Trash from "assets/trash.svg";
import UploadImg from "assets/upload.svg";
import UploadImgLarge from "assets/upload-large.png";
import Whatsapp from "assets/whatsapp.png";
import Facebook from "assets/fb.png";
import Instagram from "assets/ig.png";

import { Accordion, Modal } from "flowbite-react";
import { motion } from "framer-motion";

const SettingBeranda = ({ className }) => {
  const navigate = useNavigate();
  const img = ["Picture1", "Picture2", "Picture3", "Picture4", "Picture5"];
  const listImg = img.map((img) => (
    <img key={img} src={UploadImg} alt={img} className="cursor-pointer" />
  ));

  const htmlTag = document.querySelector("html");
  const bgColorAccordion = htmlTag.classList.contains("dark") ? "#000" : "#fff";

  const [loading, setLoading] = useState(false);

  //states for menu 1
  const [nameMenu1, setNameMenu1] = useState("Menu 1");
  const [showModal1, setShowModal1] = useState(false);
  const [itemCarousel, setItemCarousel] = useState([]);
  const [inputCarousel, setInputCarousel] = useState(null);
  const [gambarPenunjang, setGambarPenunjang] = useState([""]);
  const [filePenunjang, setFilePenunjang] = useState(null);
  const [textGambarPenunjang, setTextGambarPenunjang] = useState([""]);
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  //states for menu 2
  const [nameMenu2, setNameMenu2] = useState("Menu 2");
  const [showModal2, setShowModal2] = useState(false);
  const [itemBackground, setItemBackground] = useState([]);
  const [inputBackground, setInputBackground] = useState(null);
  const [gambarMenu2, setGambarMenu2] = useState([""]);
  const [fileGambarMenu2, setFileGambarMenu2] = useState(null);
  const [judulMenu2, setJudulMenu2] = useState([""]);
  const [deskripsiMenu2, setDeskripsiMenu2] = useState([""]);

  // states for menu 3
  const [nameMenu3, setNameMenu3] = useState("Menu 3");
  const [showModal3, setShowModal3] = useState(false);
  const [gambarMenu3, setGambarMenu3] = useState([""]);
  const [fileGambarMenu3, setFileGambarMenu3] = useState(null);
  const [kota, setKota] = useState([""]);
  const [judulMenu3, setJudulMenu3] = useState([""]);
  const [deskripsiMenu3, setDeskripsiMenu3] = useState([""]);

  // states for menu 4
  const [nameMenu4, setNameMenu4] = useState("Menu 4");
  const [showModal4, setShowModal4] = useState(false);
  const [galeryImages, setGaleryImages] = useState([]);
  const [inputGalery, setInputGalery] = useState(null);

  // const dataMenu1 = {
  //   nama_menu1: nameMenu1,
  //   file_name: itemCarousel ? itemCarousel.name : null,
  //   pic_sec1: [formPenunjang[0].images],
  //   pic_sec2: formPenunjang.length > 1 ? [formPenunjang[1].images] : [],
  //   text1: formPenunjang[0].textImage,
  //   text2: formPenunjang.length > 1 ? formPenunjang[1].textImage : null,
  //   instagram: "http://www.instagram.com/" + instagram,
  //   facebook: "http://www.facebook.com/" + facebook,
  //   whatsapp: "https://wa.me/" + whatsapp,
  // };

  // const dataMenu2 = {
  //   nama_menu2: nameMenu2,
  //   file_name2: itemGambar ? itemGambar.name : null,
  //   text1: textGambar,
  //   background: itemBackground[0],
  // };

  //handle form menu 1
  const addNewitemCarousel = (e) => {
    const copyCart = [...itemCarousel];
    if (e.target.files[0] !== null) {
      copyCart.push(e.target.files[0]);
    }
    setItemCarousel(copyCart);
    setInputCarousel(null);
  };

  const handleRemoveCarousel = (index) => {
    const copyCart = [...itemCarousel];
    copyCart.splice(index, 1);
    setItemCarousel(copyCart);
  };

  const addFormGambarPenunjang = () => {
    setGambarPenunjang([...gambarPenunjang, []]);
    setTextGambarPenunjang([...textGambarPenunjang, ""]);
  };
  const handleTextPenunjang = (index, e) => {
    const updatedValues = textGambarPenunjang.map((value, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return value;
      }
    });
    setTextGambarPenunjang(updatedValues);
  };
  const handleGambarPenunjang = (index, e) => {
    const copyCart = [gambarPenunjang];
    if (e.target.files[0] !== null) {
      copyCart.push(e.target.files[0]);
    }
    const updatedArr = gambarPenunjang.map((files, i) => {
      if (i === index) {
        return e.target.files[0];
      } else {
        return files;
      }
    });
    setGambarPenunjang(updatedArr);
    setFilePenunjang(null);
  };
  const handleRemoveFormPenunjang = (index) => {
    const listValues = [...textGambarPenunjang];
    const listArr = [...gambarPenunjang];
    listValues.splice(index, 1);
    listArr.splice(index, 1);
    setTextGambarPenunjang(listValues);
    setGambarPenunjang(listArr);
  };

  //handle form menu 2
  const addNewitemBackground = (e) => {
    const copyCart = [...itemBackground];
    if (e.target.files[0] !== null) {
      copyCart.push(e.target.files[0]);
    }
    setItemBackground(copyCart);
    setInputBackground(null);
  };

  const handleRemoveBackground = (index) => {
    const copyCart = [...itemBackground];
    copyCart.splice(index, 1);
    setItemBackground(copyCart);
  };

  const addFormMenu2 = () => {
    setGambarMenu2([...gambarMenu2, []]);
    setJudulMenu2([...judulMenu2, ""]);
    setDeskripsiMenu2([...deskripsiMenu2, ""]);
  };
  const handleJudulMenu2 = (index, e) => {
    const updatedValues = judulMenu2.map((value, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return value;
      }
    });
    setJudulMenu2(updatedValues);
  };
  const handleDeskripsiMenu2 = (index, e) => {
    const updatedValues = deskripsiMenu2.map((value, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return value;
      }
    });
    setDeskripsiMenu2(updatedValues);
  };
  const handleGambarMenu2 = (index, e) => {
    const copyCart = [gambarMenu2];
    if (e.target.files[0] !== null) {
      copyCart.push(e.target.files[0]);
    }
    const updatedArr = gambarMenu2.map((files, i) => {
      if (i === index) {
        return e.target.files[0];
      } else {
        return files;
      }
    });
    setGambarMenu2(updatedArr);
    setFileGambarMenu2(null);
  };
  const handleRemoveFormMenu2 = (index) => {
    const listJudul = [...judulMenu2];
    const listDeskripsi = [...deskripsiMenu2];
    const listArr = [...gambarMenu2];
    listJudul.splice(index, 1);
    listDeskripsi.splice(index, 1);
    listArr.splice(index, 1);
    setJudulMenu2(listJudul);
    setDeskripsiMenu2(listDeskripsi);
    setGambarMenu2(listArr);
  };

  //handle form menu 3
  const addFormMenu3 = () => {
    setGambarMenu3([...gambarMenu3, []]);
    setJudulMenu3([...judulMenu3, ""]);
    setDeskripsiMenu3([...kota, ""]);
    setDeskripsiMenu3([...deskripsiMenu3, ""]);
  };
  const handleJudulMenu3 = (index, e) => {
    const updatedValues = judulMenu3.map((value, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return value;
      }
    });
    setJudulMenu3(updatedValues);
  };
  const handleKota = (index, e) => {
    const updatedValues = kota.map((value, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return value;
      }
    });
    setKota(updatedValues);
  };
  const handleDeskripsiMenu3 = (index, e) => {
    const updatedValues = deskripsiMenu3.map((value, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return value;
      }
    });
    setDeskripsiMenu3(updatedValues);
  };
  const handleGambarMenu3 = (index, e) => {
    const copyCart = [gambarMenu3];
    if (e.target.files[0] !== null) {
      copyCart.push(e.target.files[0]);
    }
    const updatedArr = gambarMenu3.map((files, i) => {
      if (i === index) {
        return e.target.files[0];
      } else {
        return files;
      }
    });
    setGambarMenu3(updatedArr);
    setFileGambarMenu3(null);
  };
  const handleRemoveFormMenu3 = (index) => {
    const listJudul = [...judulMenu3];
    const listKota = [...kota];
    const listDeskripsi = [...deskripsiMenu3];
    const listArr = [...gambarMenu3];
    listJudul.splice(index, 1);
    listKota.splice(index, 1);
    listDeskripsi.splice(index, 1);
    listArr.splice(index, 1);
    setJudulMenu3(listJudul);
    setKota(listKota);
    setDeskripsiMenu3(listDeskripsi);
    setGambarMenu3(listArr);
  };

  //handle form menu 4
  const onFileChangeGalery = (e) => {
    const copyCart = [...galeryImages];
    if (e.target.files[0] !== null) {
      copyCart.push(e.target.files[0]);
    }
    setGaleryImages(copyCart);
    setInputGalery(null);
  };
  const handleRemoveGalery = (index) => {
    const list = [...galeryImages];
    list.splice(index, 1);
    setGaleryImages(list);
  };

  //handle post menu 1
  const postMenu1 = () => {
    // setLoading(true);
    const getToken = JSON.parse(localStorage.getItem("access_token"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer Bearer ${getToken.access_token}`);

    var formdata = new FormData();
    formdata.append("nama_menu1", nameMenu1);
    itemCarousel.map((item, index) => {
      return formdata.append(
        `file_name1[]`,
        itemCarousel[index],
        itemCarousel[index].name
      );
    });
    formdata.append("pic_sec1", gambarPenunjang[0], gambarPenunjang[0].name);
    formdata.append("pic_sec2", gambarPenunjang[1], gambarPenunjang[1].name);
    formdata.append(`text1`, textGambarPenunjang[1]);
    formdata.append(`text2`, textGambarPenunjang[2]);
    formdata.append("icon", gambarPenunjang[0], gambarPenunjang[0].name);
    formdata.append("link", `http://wa.me/${+whatsapp}`);
    formdata.append("link_fb", `http://www.facebook.com/${+facebook}`);
    formdata.append("link_ig", `http://www.instagram.com/${+instagram}`);
    gambarPenunjang.map((item, index) => {
      return formdata.append(
        `picture[${index}]`,
        gambarPenunjang[index],
        gambarPenunjang[index].name
      );
    });
    textGambarPenunjang.map((item, index) => {
      return formdata.append(`text[${index}]`, item);
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://project.inagata.com/flix/public/api/menu", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        // if (result.message === "Success") {
        //   postMenu2();
        // }
      })
      .catch((error) => console.log("error", error));
  };

  //handle post menu 2
  const postMenu2 = () => {
    // setLoading(true);
    const getToken = JSON.parse(localStorage.getItem("access_token"));
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer Bearer ${getToken.access_token}`);

    var formdata = new FormData();
    formdata.append("nama_menu2", nameMenu2);
    // formdata.append("file_name2[]", fileInput.files[0], "[PROXY]");
    // formdata.append("file_name2[]", fileInput.files[0], "[PROXY]");
    gambarMenu2.map((item, index) => {
      return formdata.append(
        `file_name2[]`,
        gambarMenu2[index],
        gambarMenu2[index].name
      );
    });
    formdata.append("keterangan2", judulMenu2[0]);
    formdata.append("background2", itemBackground[0], itemBackground[0].name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://project.inagata.com/flix/public/api/menu2", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        // if (result.message === "Success") {
        //   postMenu3();
        // }
      })
      .catch((error) => console.log("error", error));
  };

  //handle post menu 3
  const postMenu3 = () => {
    const getToken = JSON.parse(localStorage.getItem("access_token"));
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer Bearer ${getToken.access_token}`);

    var formdata = new FormData();
    formdata.append("nama_menu", nameMenu3);
    formdata.append("judul", judulMenu3);
    formdata.append("lokasi", kota);
    formdata.append("deskripsi", deskripsiMenu3);
    gambarMenu3.map((item, index) => {
      return formdata.append(
        `gambar[]`,
        gambarMenu3[index],
        gambarMenu3[index].name
      );
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://project.inagata.com/flix/public/api/info", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        // if (result.message === "Success") {
        //   postMenu4();
        // }
      })
      .catch((error) => console.log("error", error));
  };

  //handle post menu 4
  const postMenu4 = () => {
    // setLoading(true);
    const getToken = JSON.parse(localStorage.getItem("access_token"));
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer Bearer ${getToken.access_token}`);

    var formdata = new FormData();
    formdata.append("nama_menu5", nameMenu4);
    formdata.append("gambar5", galeryImages[0], galeryImages[0].name);
    galeryImages.map((item, index) => {
      return formdata.append(
        `file_name5[]`,
        galeryImages[index],
        `${galeryImages[index].name}`
      );
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://project.inagata.com/flix/public/api/menu5", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        // if (result.message === "Success") {
        //   setLoading(false);
        //   navigate("/home");
        // }
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const handleSubmit = () => {
    navigate("/home");
    postMenu1();
    postMenu2();
    postMenu3();
    postMenu4();
    setLoading(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} bg-white dark:bg-black`}
    >
      <HeaderSecondary
        headerStyles={"header-secondary-settings"}
        children={"Setting Beranda"}
        redirect={null}
      />

      <div className="p-5">
        {/* <div className="flex items-center gap-4">
          <button type="button" className="bg-[#CED8E4] rounded-lg">
            <img src={Add} alt="Add" className="p-2" />
          </button>
          <span className="font-medium dark:text-white">Tambah Menu</span>
        </div> */}

        <div className="mt-5">
          <Accordion flush={true}>
            <Accordion.Panel open={true}>
              <Accordion.Title
                arrowIcon={undefined}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <div className="flex items-center gap-4">
                  <span className="font-bold text-xl dark:text-white">
                    {nameMenu1}
                  </span>
                  <button onClick={() => setShowModal1(true)}>
                    <img
                      src={Pencil}
                      alt="Change"
                      className="dark:pencil-dark"
                    />
                  </button>

                  <span className="bg-[#FAB1B1] rounded-md z-10">
                    <img src={Trash} alt="Del" className="p-0.5" />
                  </span>
                </div>
              </Accordion.Title>
              <Accordion.Content
                style={{
                  borderTopWidth: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  backgroundColor: bgColorAccordion,
                }}
              >
                {showModal1 ? (
                  <Modal show={showModal1} onClose={() => setShowModal1(false)}>
                    <Modal.Header>Ganti Nama Menu 1</Modal.Header>
                    <Modal.Body>
                      <input
                        type="text"
                        className="w-full h-12 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                        placeholder="Nama menu"
                        maxLength={200}
                        value={nameMenu1}
                        onChange={(e) => setNameMenu1(e.target.value)}
                      />
                      <button
                        className="block mt-3 ml-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => setShowModal1(false)}
                      >
                        OK
                      </button>
                    </Modal.Body>
                  </Modal>
                ) : null}

                {/* carousel-pic */}
                <div className="w-full">
                  <p className="font-medium text-sm mb-5 dark:text-white">
                    Gambar Utama/Slider
                  </p>
                  <input
                    id="uploadCarousel"
                    // value={inputCarousel}
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={addNewitemCarousel}
                    disabled={itemCarousel.length === 5 ? true : false}
                    hidden
                  />
                  <label
                    htmlFor="uploadCarousel"
                    className="upload dark:upload-dark"
                  >
                    Upload Gambar
                  </label>
                  <p className="mt-3 mb-4 text-xs text-[#838282] dark:text-white">
                    Max.5
                  </p>
                  <div className="mt-3 flex items-center gap-2">
                    {itemCarousel.length > 0
                      ? itemCarousel.map((subitemCarousel, sIndex) => {
                          return (
                            <div className="relative">
                              <img
                                key={sIndex}
                                src={URL.createObjectURL(subitemCarousel)}
                                className="image-item"
                                title={subitemCarousel}
                                alt="item"
                              />
                              <button
                                className="bg-[#FAB1B1] rounded-md z-10 btn-delete-img"
                                title="Hapus Background"
                                onClick={() => handleRemoveCarousel(sIndex)}
                              >
                                <img src={Trash} alt="Del" className="p-0.5" />
                              </button>
                            </div>
                          );
                        })
                      : listImg}
                  </div>
                </div>

                {/* supporting-pic */}
                <div className="w-full mt-6">
                  <div className="flex items-center justify-between">
                    <p className="font-medium text-sm dark:text-white">
                      Gambar Penunjang
                    </p>
                  </div>
                </div>
                {
                  <div className="mb-4">
                    <div className="flex flex-col">
                      {gambarPenunjang.map((item, index) => (
                        <div style={{ order: index }}>
                          <div className="flex items-center justify-between mt-4 mb-5">
                            <div>
                              <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                id={`gambarPenunjang ${index}`}
                                onChange={(e) =>
                                  handleGambarPenunjang(index, e)
                                }
                                // disabled={
                                //   itemCarousel.length === 1 ? true : false
                                // }
                                hidden
                              />
                              <label
                                htmlFor={`gambarPenunjang ${index}`}
                                className="upload dark:upload-dark"
                              >
                                Upload Gambar
                              </label>
                            </div>
                            <div className="flex items-center gap-2">
                              {textGambarPenunjang.length !== 1 && (
                                <button
                                  className="bg-[#FAB1B1] rounded-md cursor-pointer"
                                  title={`Hapus Form Penunjang ${index + 1}`}
                                  onClick={() =>
                                    handleRemoveFormPenunjang(index)
                                  }
                                >
                                  <img
                                    src={Trash}
                                    alt="Del"
                                    className="p-0.5"
                                  />
                                </button>
                              )}
                              {textGambarPenunjang.length - 1 === index && (
                                <button
                                  className="bg-[#CED8E4] rounded-md cursor-pointer"
                                  title="Tambah Form Penunjang"
                                  onClick={addFormGambarPenunjang}
                                >
                                  <img src={Add} alt="Add" width={"20"} />
                                </button>
                              )}
                            </div>
                          </div>
                          {/* <p className="mt-3 mb-4 text-xs text-[#838282] dark:text-white">
                            Max.5
                          </p> */}
                          {item.length === 0 ? (
                            <img
                              key={img}
                              src={UploadImg}
                              alt={img}
                              className="cursor-pointer"
                            />
                          ) : (
                            <img
                              key={index}
                              src={URL.createObjectURL(item)}
                              className="image-item"
                              alt={`gambar penunjang ${index + 1}`}
                              title={`gambar penunjang ${index + 1}`}
                            />
                          )}
                        </div>
                      ))}
                      {textGambarPenunjang.map((jump, index) => (
                        <div style={{ order: index }}>
                          {/* <input
                            type="text"
                            value={jump || ""}
                            placeholder={`Text Gambar ${index}`}
                            onChange={(e) => handleTextPenunjang(index, e)}
                          /> */}
                          <div className="w-full mt-6">
                            <div className="flex items-center justify-between mb-3">
                              <p className="font-medium text-sm dark:text-white">
                                Text Image {index + 1}
                              </p>
                            </div>

                            <div className="relative">
                              <input
                                type="text"
                                placeholder={"Teks Gambar " + (index + 1)}
                                className="w-full h-12 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                                maxLength={200}
                                onChange={(e) => handleTextPenunjang(index, e)}
                                value={jump || ""}
                              />
                              <div className="text-sm absolute right-0 top-[14px] pr-3 dark:text-white">
                                {jump.length}
                                <span className="text-scienceBlue">/200</span>
                              </div>
                            </div>
                          </div>
                          {/* <button onClick={() => handleRemoveClick(index)} disabled={values.length == 1 ? true : false}>delete all</button> */}
                        </div>
                      ))}
                    </div>
                  </div>
                }

                {/* social-media */}
                <div className="w-full mt-6">
                  <div className="flex items-center justify-between mb-5">
                    <p className="font-medium text-sm dark:text-white">
                      Social Media
                    </p>
                  </div>

                  <div className="w-full flex items-center justify-between gap-4 mb-3">
                    <div className="flex items-center">
                      <img src={Whatsapp} alt="icon" />
                    </div>

                    <input
                      type="text"
                      placeholder="+628..."
                      className="w-full h-10 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-2xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                      maxLength={14}
                      value={whatsapp}
                      onChange={(e) => setWhatsapp(e.target.value)}
                    />
                  </div>
                  <div className="w-full flex items-center justify-between gap-4 mb-3">
                    <div className="flex items-center">
                      <img src={Facebook} alt="icon" />
                    </div>

                    <input
                      type="text"
                      placeholder="Username"
                      className="w-full h-10 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-2xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                      value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                    />
                  </div>
                  <div className="w-full flex items-center justify-between gap-4">
                    <div className="flex items-center">
                      <img src={Instagram} alt="icon" />
                    </div>

                    <input
                      type="text"
                      placeholder="Username"
                      className="w-full h-10 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-2xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                    />
                  </div>
                </div>
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel open={true}>
              <Accordion.Title
                arrowIcon={undefined}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <div className="flex items-center gap-4">
                  <span className="font-bold text-xl dark:text-white">
                    {nameMenu2}
                  </span>

                  <button onClick={() => setShowModal2(true)}>
                    <img
                      src={Pencil}
                      alt="Change"
                      className="dark:pencil-dark"
                    />
                  </button>

                  <span className="bg-[#FAB1B1] rounded-md">
                    <img src={Trash} alt="Del" className="p-0.5" />
                  </span>
                </div>
              </Accordion.Title>
              <Accordion.Content
                style={{
                  borderTopWidth: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  backgroundColor: bgColorAccordion,
                }}
              >
                {showModal2 ? (
                  <Modal show={showModal2} onClose={() => setShowModal2(false)}>
                    <Modal.Header>Ganti Nama Menu 2</Modal.Header>
                    <Modal.Body>
                      <input
                        type="text"
                        className="w-full h-12 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                        placeholder="Nama menu"
                        maxLength={200}
                        value={nameMenu2}
                        onChange={(e) => setNameMenu2(e.target.value)}
                      />
                      <button
                        className="block mt-3 ml-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => setShowModal2(false)}
                      >
                        OK
                      </button>
                    </Modal.Body>
                  </Modal>
                ) : null}
                {/* background-pic */}
                <div className="w-full">
                  <p className="font-medium text-sm mb-5 dark:text-white">
                    Background
                  </p>
                  <input
                    id="uploadBackground"
                    // value={inputBackground}
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={addNewitemBackground}
                    disabled={itemBackground.length === 1 ? true : false}
                    hidden
                  />
                  <label
                    htmlFor="uploadBackground"
                    className="upload dark:upload-dark"
                  >
                    Upload Gambar
                  </label>
                  {itemBackground.length > 0 ? (
                    itemBackground.map((subitemBackgrounds, sIndex) => {
                      return (
                        <div className="relative">
                          <img
                            key={sIndex}
                            src={URL.createObjectURL(subitemBackgrounds)}
                            className="image-item-xl my-5"
                            alt={subitemBackgrounds}
                          />
                          <button
                            className="bg-[#FAB1B1] rounded-md cursor-pointer btn-delete-img"
                            title="Hapus Background"
                            onClick={() => handleRemoveBackground(sIndex)}
                          >
                            <img src={Trash} alt="Del" className="p-0.5" />
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <img src={UploadImgLarge} className="my-5" alt="upload" />
                  )}
                </div>

                {/* text-image */}
                <div className="w-full mt-6">
                  <div className="flex items-center justify-between mb-1">
                    <p className="font-medium text-sm dark:text-white">
                      Gambar
                    </p>
                  </div>
                </div>
                {
                  <div className="mb-4">
                    <div className="flex flex-col">
                      {gambarMenu2.map((item, index) => (
                        <div style={{ order: index }}>
                          <div className="flex items-center justify-between mt-4 mb-5">
                            <div>
                              <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                id={`gambarMenu2 ${index}`}
                                onChange={(e) => handleGambarMenu2(index, e)}
                                // disabled={
                                //   gambarMenu2.length === 1 ? true : false
                                // }
                                hidden
                              />
                              <label
                                htmlFor={`gambarMenu2 ${index}`}
                                className="upload dark:upload-dark"
                              >
                                Upload Gambar
                              </label>
                            </div>
                            {/* <div className="flex items-center gap-2">
                              {judulMenu2.length !== 1 && (
                                <button
                                  className="bg-[#FAB1B1] rounded-md cursor-pointer"
                                  title={`Hapus Form Menu2 ${index + 1}`}
                                  onClick={() => handleRemoveFormMenu2(index)}
                                >
                                  <img
                                    src={Trash}
                                    alt="Del"
                                    className="p-0.5"
                                  />
                                </button>
                              )}
                              {judulMenu2.length - 1 === index && (
                                <button
                                  className="bg-[#CED8E4] rounded-md cursor-pointer"
                                  title="Tambah Form Menu2"
                                  onClick={addFormMenu2}
                                >
                                  <img src={Add} alt="Add" width={"20"} />
                                </button>
                              )}
                            </div> */}
                          </div>
                          {/* <p className="mt-3 mb-4 text-xs text-[#838282] dark:text-white">
                            Max.5
                          </p> */}
                          {item.length === 0 ? (
                            <img
                              key={img}
                              src={UploadImg}
                              alt={img}
                              className="cursor-pointer"
                            />
                          ) : (
                            <img
                              key={index}
                              src={URL.createObjectURL(item)}
                              className="image-item"
                              alt={`gambar menu2 ${index + 1}`}
                              title={`gambar menu2 ${index + 1}`}
                            />
                          )}
                        </div>
                      ))}
                      {judulMenu2.map((jump, index) => (
                        <div style={{ order: index }}>
                          <div className="w-full mt-6">
                            <div className="flex items-center justify-between mb-3">
                              <p className="font-medium text-sm dark:text-white">
                                Keterangan {index + 1}
                              </p>
                            </div>

                            <div className="relative">
                              <input
                                type="text"
                                placeholder={"Judul Gambar " + (index + 1)}
                                className="w-full h-12 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                                maxLength={200}
                                onChange={(e) => handleJudulMenu2(index, e)}
                                value={jump || ""}
                              />
                              <div className="text-sm absolute right-0 top-[14px] pr-3 dark:text-white">
                                {jump.length}
                                <span className="text-scienceBlue">/200</span>
                              </div>
                            </div>
                          </div>
                          {/* <button onClick={() => handleRemoveClick(index)} disabled={values.length == 1 ? true : false}>delete all</button> */}
                        </div>
                      ))}
                      {deskripsiMenu2.map((jump, index) => (
                        <div style={{ order: index }}>
                          <div className="w-full mt-3">
                            <div className="relative">
                              <textarea
                                placeholder={"Deskripsi Gambar " + (index + 1)}
                                rows="10"
                                className="w-full pr-[64px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                                maxLength={200}
                                value={jump || ""}
                                onChange={(e) => handleDeskripsiMenu2(index, e)}
                              ></textarea>
                              <div className="text-sm absolute right-0 top-[14px] pr-3 dark:text-white">
                                {jump.length}
                                <span className="text-scienceBlue">/200</span>
                              </div>
                            </div>
                          </div>
                          {/* <button onClick={() => handleRemoveClick(index)} disabled={values.length == 1 ? true : false}>delete all</button> */}
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel open={true}>
              <Accordion.Title
                arrowIcon={undefined}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <div className="flex items-center gap-4">
                  <span className="font-bold text-xl dark:text-white">
                    {nameMenu3}
                  </span>

                  <button onClick={() => setShowModal3(true)}>
                    <img
                      src={Pencil}
                      alt="Change"
                      className="dark:pencil-dark"
                    />
                  </button>

                  <span className="bg-[#FAB1B1] rounded-md z-10">
                    <img src={Trash} alt="Del" className="p-0.5" />
                  </span>
                </div>
              </Accordion.Title>
              <Accordion.Content
                style={{
                  borderTopWidth: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  backgroundColor: bgColorAccordion,
                }}
              >
                {showModal3 ? (
                  <Modal show={showModal3} onClose={() => setShowModal3(false)}>
                    <Modal.Header>Ganti Nama Menu 3</Modal.Header>
                    <Modal.Body>
                      <input
                        type="text"
                        className="w-full h-12 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                        placeholder="Nama menu"
                        maxLength={200}
                        value={nameMenu3}
                        onChange={(e) => setNameMenu3(e.target.value)}
                      />
                      <button
                        className="block mt-3 ml-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => setShowModal3(false)}
                      >
                        OK
                      </button>
                    </Modal.Body>
                  </Modal>
                ) : null}
                <div className="w-full">
                  <div className="flex items-center justify-between">
                    <p className="font-medium text-sm dark:text-white">
                      Gambar
                    </p>
                  </div>
                </div>
                {
                  <div className="mb-4">
                    <div className="flex flex-col">
                      {gambarMenu3.map((item, index) => (
                        <div style={{ order: index }}>
                          <div className="flex items-center justify-between mt-4 mb-5">
                            <div>
                              <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                id={`gambarMenu3 ${index}`}
                                onChange={(e) => handleGambarMenu3(index, e)}
                                // disabled={
                                //   gambarMenu3.length === 1 ? true : false
                                // }
                                hidden
                              />
                              <label
                                htmlFor={`gambarMenu3 ${index}`}
                                className="upload dark:upload-dark"
                              >
                                Upload Gambar
                              </label>
                            </div>
                            {/* <div className="flex items-center gap-2">
                              {judulMenu3.length !== 1 && (
                                <button
                                  className="bg-[#FAB1B1] rounded-md cursor-pointer"
                                  title={`Hapus Form Menu3 ${index + 1}`}
                                  onClick={() => handleRemoveFormMenu3(index)}
                                >
                                  <img
                                    src={Trash}
                                    alt="Del"
                                    className="p-0.5"
                                  />
                                </button>
                              )}
                              {judulMenu3.length - 1 === index && (
                                <button
                                  className="bg-[#CED8E4] rounded-md cursor-pointer"
                                  title="Tambah Form Menu3"
                                  onClick={addFormMenu3}
                                >
                                  <img src={Add} alt="Add" width={"20"} />
                                </button>
                              )}
                            </div> */}
                          </div>
                          {/* <p className="mt-3 mb-4 text-xs text-[#838282] dark:text-white">
                            Max.5
                          </p> */}
                          {item.length === 0 ? (
                            <img
                              key={img}
                              src={UploadImg}
                              alt={img}
                              className="cursor-pointer"
                            />
                          ) : (
                            <img
                              key={index}
                              src={URL.createObjectURL(item)}
                              className="image-item"
                              alt={`gambar menu3 ${index + 1}`}
                              title={`gambar menu3 ${index + 1}`}
                            />
                          )}
                        </div>
                      ))}
                      {judulMenu3.map((jump, index) => (
                        <div style={{ order: index }}>
                          <div className="w-full mt-6">
                            <div className="flex items-center justify-between mb-3">
                              <p className="font-medium text-sm dark:text-white">
                                Keterangan {index + 1}
                              </p>
                            </div>

                            <div className="relative">
                              <input
                                type="text"
                                placeholder={"Judul Gambar " + (index + 1)}
                                className="w-full h-12 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                                maxLength={200}
                                onChange={(e) => handleJudulMenu3(index, e)}
                                value={jump || ""}
                              />
                              <div className="text-sm absolute right-0 top-[14px] pr-3 dark:text-white">
                                {jump.length}
                                <span className="text-scienceBlue">/200</span>
                              </div>
                            </div>
                          </div>
                          {/* <button onClick={() => handleRemoveClick(index)} disabled={values.length == 1 ? true : false}>delete all</button> */}
                        </div>
                      ))}
                      {kota.map((jump, index) => (
                        <div style={{ order: index }}>
                          <div className="w-full mt-3">
                            <div className="relative">
                              <input
                                type="text"
                                placeholder={"Lokasi Info " + (index + 1)}
                                className="w-full h-12 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                                maxLength={200}
                                onChange={(e) => handleKota(index, e)}
                                value={jump || ""}
                              />
                              <div className="text-sm absolute right-0 top-[14px] pr-3 dark:text-white">
                                {jump.length}
                                <span className="text-scienceBlue">/200</span>
                              </div>
                            </div>
                          </div>
                          {/* <button onClick={() => handleRemoveClick(index)} disabled={values.length == 1 ? true : false}>delete all</button> */}
                        </div>
                      ))}
                      {deskripsiMenu3.map((jump, index) => (
                        <div style={{ order: index }}>
                          <div className="w-full mt-3">
                            <div className="relative">
                              <textarea
                                placeholder={"Deskripsi Gambar " + (index + 1)}
                                rows="10"
                                className="w-full pr-[64px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                                maxLength={200}
                                value={jump || ""}
                                onChange={(e) => handleDeskripsiMenu3(index, e)}
                              ></textarea>
                              <div className="text-sm absolute right-0 top-[14px] pr-3 dark:text-white">
                                {jump.length}
                                <span className="text-scienceBlue">/200</span>
                              </div>
                            </div>
                          </div>
                          {/* <button onClick={() => handleRemoveClick(index)} disabled={values.length == 1 ? true : false}>delete all</button> */}
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel open={true}>
              <Accordion.Title
                arrowIcon={undefined}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <div className="flex items-center gap-4">
                  <span className="font-bold text-xl dark:text-white">
                    {nameMenu4}
                  </span>

                  <button onClick={() => setShowModal4(true)}>
                    <img
                      src={Pencil}
                      alt="Change"
                      className="dark:pencil-dark"
                    />
                  </button>

                  <span className="bg-[#FAB1B1] rounded-md z-10">
                    <img src={Trash} alt="Del" className="p-0.5" />
                  </span>
                </div>
              </Accordion.Title>
              <Accordion.Content
                style={{
                  borderTopWidth: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  backgroundColor: bgColorAccordion,
                }}
              >
                {showModal4 ? (
                  <Modal show={showModal4} onClose={() => setShowModal4(false)}>
                    <Modal.Header>Ganti Nama Menu 4</Modal.Header>
                    <Modal.Body>
                      <input
                        type="text"
                        className="w-full h-12 pr-[68px] text-[#838282] border-[#E0E0E0] text-sm rounded-xl dark:bg-black dark:border-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-scienceBlue"
                        placeholder="Nama menu"
                        maxLength={200}
                        value={nameMenu4}
                        onChange={(e) => setNameMenu4(e.target.value)}
                      />
                      <button
                        className="block mt-3 ml-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => setShowModal4(false)}
                      >
                        OK
                      </button>
                    </Modal.Body>
                  </Modal>
                ) : null}
                <div className="w-full">
                  <div className="flex items-center justify-between mb-5">
                    <p className="font-medium text-sm dark:text-white">
                      Gambar Galeri
                    </p>
                    {/* <div className="flex items-center gap-2">
                      <button className="bg-[#CED8E4] rounded-md cursor-pointer" title="Tambah Keterangan" onClick={handleAddClick}>
                        <img src={Add} alt="Add" width={"20"} />
                      </button>

                      <button className="bg-[#FAB1B1] rounded-md cursor-pointer" title="Hapus Keterangan" onClick={handleRemoveClick}>
                        <img src={Trash} alt="Del" className="p-0.5" />
                      </button>
                    </div> */}
                  </div>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    id="inputGalery"
                    onChange={onFileChangeGalery}
                    disabled={galeryImages.length === 5 ? true : false}
                    hidden
                  />
                  <label
                    htmlFor="inputGalery"
                    className="upload dark:upload-dark"
                  >
                    Upload Gambar
                  </label>
                  <p className="mt-3 mb-4 text-xs text-[#838282] dark:text-white">
                    Max.5
                  </p>
                  <div className="mt-3 flex items-center gap-2">
                    {galeryImages.length !== 0
                      ? galeryImages.map((item, index) => {
                          return (
                            <div className="relative">
                              <img
                                key={"image galery " + (index + 1)}
                                className="image-item"
                                src={URL.createObjectURL(item)}
                                alt={index + 1}
                              />
                              <button
                                className="bg-[#FAB1B1] rounded-md z-10 btn-delete-img"
                                title="Hapus Background"
                                onClick={() => handleRemoveGalery(index)}
                              >
                                <img src={Trash} alt="Del" className="p-0.5" />
                              </button>
                            </div>
                          );
                        })
                      : listImg}
                  </div>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>

        <div className="mt-8">
          {/* <Link to={"/home"}> */}
          {/* <Button type={"button"} onClick={handleSubmit} btnStyle={"btn-primary"}>
              Lihat Preview
            </Button> */}
          <button
            className="btn-primary btn-full btn-primary disabled:transform-none disabled:transition-none disabled:bg-scienceBlue disabled:text-white disabled:cursor-not-allowed"
            type="button"
            disabled={loading === true ? true : false}
            onClick={handleSubmit}
          >
            {loading === true ? (
              <div className="flex justify-center align-center">
                <svg
                  className="motion-reduce:hidden animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              "Lihat Preview"
            )}
          </button>
          {/* </Link> */}
        </div>
      </div>
    </motion.div>
  );
};

export default SettingBeranda;
