import HeaderSecondary from "components/Header/HeaderSecondary";
import Forgot from "assets/forgot-password.png";
import Email from "assets/email.svg";
import Eye from "assets/eye.svg";
import Key from "assets/key.svg";
import "pages/UserGate/UserGate.css";

import Button from "components/Button";

import { useNavigate } from "react-router-dom"; 
import { motion } from "framer-motion";
import { useState } from "react";

const Registrasi = ({ className }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("password", password);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://project.inagata.com/flix/public/api/auth/register",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {    
        if (result.email === "The email has already been taken.") {
          alert('Email sudah terdaftar')
          setLoading(false)
        } else {
          setLoading(false)
          navigate('/login')
        }
      })
      .catch((error) => console.log("error", error));
  };

  function showHide() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} bg-white`}
    >
      <HeaderSecondary
        headerStyles={"header-secondary-inverted"}
        redirect={"/"}
      />

      <div className="px-7 py-5 my-5">
        <img src={Forgot} alt="Lupa Password" className="mb-7 mx-auto" />
        <h3 className="font-bold text-2xl mb-1">Daftar Akun Baru</h3>

        <form onSubmit={handleSubmit}>
          <div className="inputWithIcon inputIconBg">
            <input
              className="user-gate"
              type="text"
              placeholder="Username"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
            <img src={Email} alt="Username" className="icon-left" />
          </div>
          <div className="inputWithIcon inputIconBg">
            <input
              className="user-gate"
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <img src={Email} alt="Email" className="icon-left" />
          </div>
          <div className="inputWithIcon inputIconBg">
            <input
              className="user-gate"
              type="password"
              id="password"
              placeholder="Password"
              required
              minLength="8"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img src={Key} alt="Key" className="icon-left" />
            <img
              src={Eye}
              alt="show/hide"
              className="icon-right"
              onClick={showHide}
            />
          </div>
          {/* <Link to={"/login"}> */}
          <Button type={"submit"} btnStyle={"btn-primary"} className={'disabled:transform-none disabled:transition-none disabled:bg-scienceBlue disabled:text-white disabled:cursor-not-allowed'} disabled={loading === true ? true : false}>
            {
              loading === true ? (
                <div className="flex justify-center align-center">
                  <svg className="motion-reduce:hidden animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              ) : (
                'Daftar'
              )
            }
            
          </Button>
          {/* </Link> */}
        </form>
      </div>
    </motion.div>
  );
};

export default Registrasi;
