import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/bundle";

import Comma from "assets/comma-inverted.svg";
import "./CarouselHome.css";

const CarouselHome = ({ addClass }) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return "<span class='" + className + "'></span>";
    },
  };

  return (
    <div className={addClass}>
      <Swiper
        pagination={pagination}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="slide h-[250px]">
            <img className="mb-5" src={Comma} alt="" />

            <span className="text-base font-normal block">
              “Sangat membantu saya dalam menjalankan bisnis saya.”
            </span>

            <h2 className="text-base font-bold mt-8">Charles Prasetya</h2>

            <p className="text-[10px] opacity-60 font-medium mt-1">
              Owner of Heaven
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide h-[250px]">
            <img className="mb-5" src={Comma} alt="" />

            <span className="text-base font-normal block">
              “Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
              nisi.”
            </span>

            <h2 className="text-base font-bold mt-8">Modrac Modric</h2>

            <p className="text-[10px] opacity-60 font-medium mt-1">
              Owner of Heaven
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide h-[250px]">
            <img className="mb-5" src={Comma} alt="" />

            <span className="text-base font-normal block">
              “Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Ratione.”
            </span>

            <h2 className="text-base font-bold mt-8">Monic</h2>

            <p className="text-[10px] opacity-60 font-medium mt-1">
              Owner of Heaven
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default CarouselHome;
