import CarouselHome from "components/Carousel/CarouselHome";
import HeaderPrimary from "components/Header/HeaderPrimary";
import Button from "components/Button";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

const Home = ({ className }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} px-4 py-5 bg-white flex flex-col justify-between`}
    >
      <HeaderPrimary />
      <CarouselHome />

      {/* Button Components */}
      <div id="button">
        <Link to={"/login"}>
          <Button type={"button"}>Masuk</Button>
        </Link>

        <Link to={"/registrasi"}>
          <Button
            type={"button"}
            btnStyle={"btn-primary-inverted"}
            className={"mt-4"}
          >
            Registrasi
          </Button>
        </Link>
      </div>
      {/* End of Button Components */}
    </motion.div>
  );
};

export default Home;
