import React, { useEffect, useState } from "react";

import { Link, useLocation, useParams } from "react-router-dom";

const NavHome = ({ className }) => {
  const location = useLocation();
  const param = useParams();

  const [menu1, setMenu1] = useState("");
  const [menu2, setMenu2] = useState("");
  const [menu3, setMenu3] = useState("");
  const [menu4, setMenu4] = useState("");

  useEffect(() => {
    getMenu1();
    getMenu2();
    getMenu3();
    getMenu4();
  }, []);
  const getMenu1 = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", param.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://project.inagata.com/flix/public/api/index", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        setMenu1(result.data.Menu1.reverse()[0].nama_menu1);
        console.log(result, "tes 1");
      })
      .catch((error) => console.log("error", error));
  };

  const getMenu2 = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", param.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://project.inagata.com/flix/public/api/index-menu2",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        setMenu2(result.data.Menu2.reverse()[0].nama_menu2);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const getMenu3 = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", param.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://project.inagata.com/flix/public/api/index-info",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        setMenu3(result.data.Menu_info.reverse()[0].nama_menu);
        console.log(result, "get data");
      })
      .catch((error) => console.log("error", error));
  };

  const getMenu4 = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", param.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://project.inagata.com/flix/public/api/index-menu5",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        setMenu4(result.data.Menu5.reverse()[0].nama_menu5);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div
      className={`${className} bg-[#D5E7FF] font-semibold text-scienceBlue flex items-center justify-around dark:bg-scienceBlue dark:text-white`}
    >
      <Link
        to={`/${param.name}/home`}
        className={
          location.pathname === `/${param.name}/home`
            ? "dark:text-[#f7c600]"
            : "opacity-40"
        }
      >
        {menu1}
      </Link>
      <Link
        to={`/${param.name}/produk`}
        className={
          location.pathname === `/${param.name}/produk`
            ? "dark:text-[#f7c600]"
            : "opacity-40"
        }
      >
        {menu2}
      </Link>
      <Link
        to={`/${param.name}/info`}
        className={
          location.pathname === `/${param.name}/info`
            ? "dark:text-[#f7c600]"
            : "opacity-40"
        }
      >
        {menu3}
      </Link>
      <Link
        to={`/${param.name}/galeri`}
        className={
          location.pathname === `/${param.name}/galeri`
            ? "dark:text-[#f7c600]"
            : "opacity-40"
        }
      >
        {menu4}
      </Link>
    </div>
  );
};

export default NavHome;
