import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Whatsapp from "assets/whatsapp.png";
import Facebook from "assets/fb.png";
import Instagram from "assets/ig.png";

const Footer = () => {
  let params = useParams();

  const [link, setLink] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLink();
  }, []);

  const getLink = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", params.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://project.inagata.com/flix/public/api/index", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        setLoading(true);
        setLink(result.data.Contact.reverse()[0]);
        if (result.message === "Success") {
          setLoading(false);
        }
        // console.log(result);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div>
      {link ? (
        <>
          <h3 className="font-bold text-xs dark:text-white">Visit Us</h3>

          <div className="flex gap-3 my-1">
            <a
              href={loading === true ? null : link.link}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={Whatsapp} alt="Whatsapp" className="w-[20px]" />
            </a>
            <a
              href={loading === true ? null : link.link_fb}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={Facebook} alt="Facebook" className="w-[20px]" />
            </a>
            <a
              href={loading === true ? null : link.link_ig}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={Instagram} alt="Instagram" className="w-[20px]" />
            </a>
          </div>
        </>
      ) : (
        ""
      )}

      <hr className="bg-scienceBlue h-[2px] mt-7 mb-5 dark:bg-[#f7c600]" />

      <p className="font-normal text-[11px] text-center dark:text-white">
        Copyright © 2022. Made by Flixs.
      </p>
    </div>
  );
};

export default Footer;
