import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./Home.css";

import NavHome from "components/Nav/NavHome";
import Footer from "./Footer";
import Button from "components/Button";

const Home = ({ className }) => {
  let params = useParams();

  const [loading, setLoading] = useState(false);
  const [profileWeb, setProfileWeb] = useState({});
  const [card, setCard] = useState([]);
  const [carousel, setCarousel] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const htmlTag = document.querySelector("html");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", params.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://project.inagata.com/flix/public/api/index", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        setLoading(true);
        setProfileWeb(result.data.Profile_web);
        setCarousel(result.data.Multiple_menu_1.reverse());
        setCard(result.data.Menu1.reverse());
        console.log(result, "tes 1");
      })
      .catch((error) => console.log("error", error));
  };

  console.log(profileWeb);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} min-h-[100vh] bg-white dark:bg-black`}
    >
      {loading === false
        ? null
        : profileWeb.tema === "gelap"
        ? htmlTag.classList.add("dark")
        : htmlTag.classList.add("light")}

      {/* contents */}
      <div className="px-5 pb-5 pt-1">
        <Link
          to={`${loading === false ? null : profileWeb.set_path_web}`}
          className="my-3 flex gap-2 items-center justify-center"
        >
          <img
            src={loading === false ? null : profileWeb.ikon}
            alt="icon"
            className="w-[52.59px]"
          />
          <h3 className="font-bold dark:text-white">
            {loading === false ? null : profileWeb.nama_web}
          </h3>
        </Link>

        {/* pictures */}
        <div className="grid grid-cols-2 gap-y-4 gap-x-3 w-full mt-7 mb-5">
          <div className="col-span-2">
            <Slider {...settings}>
              {loading === false
                ? null
                : carousel.slice(0, 5).map((item, index) => {
                    return (
                      <div className="relative" key={index}>
                        <img
                          src={item.file_name1}
                          alt={item.file_name1}
                          className="rounded-2xl"
                        />
                        <div
                          className="w-full h-[120px] rounded-2xl absolute bottom-0 left-0"
                          style={{
                            background:
                              "linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 95, 220, 0.5), rgba(255,0,0,0) 70%)",
                          }}
                        ></div>
                      </div>
                    );
                  })}
            </Slider>
          </div>

          {loading === false
            ? null
            : card.slice(0, 5).map((item) => {
                return (
                  <>
                    <div className="relative">
                      <img
                        src={item.pic_sec1}
                        alt="carousel-car"
                        className="rounded-2xl h-[120px]"
                      />
                      <div
                        className="w-full h-[120px] rounded-2xl absolute bottom-0 left-0"
                        style={{
                          background:
                            "linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 95, 220, 0.5), rgba(255,0,0,0) 70%)",
                        }}
                      >
                        <h3 className="font-semibold text-white absolute bottom-3 left-3 text-xs">
                          {item.text1}
                        </h3>
                      </div>
                    </div>

                    <div className="relative">
                      <img
                        src={item.pic_sec2}
                        alt="carousel-car"
                        className="rounded-2xl h-[120px]"
                      />

                      <div
                        className="w-full h-[120px] rounded-2xl absolute bottom-0 left-0"
                        style={{
                          background:
                            "linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 95, 220, 0.5), rgba(255,0,0,0) 70%)",
                        }}
                      >
                        <h3 className="font-semibold text-white absolute bottom-3 left-3 text-xs">
                          {item.text1}
                        </h3>
                      </div>
                    </div>
                  </>
                );
              })}
        </div>

        {/* footer */}
        <Footer />
      </div>

      {/* Navigation */}
      <NavHome className={`text-sm p-6`} />

      {/* Buttons */}
      <div className="px-5 pb-5 pt-3 dark:bg-[#282828]">
        <Link to={"/"}>
          <Button type={"button"}>Simpan dan Publish</Button>
        </Link>

        <Link to={"/settings"}>
          <Button
            type={"button"}
            btnStyle={"btn-primary-inverted"}
            className={"mt-4"}
          >
            Kembali Edit
          </Button>
        </Link>
      </div>
    </motion.div>
  );
};

export default Home;
