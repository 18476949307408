import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";

import SuperCar from "assets/supercar.jpg";

import NavHome from "components/Nav/NavHome";
import Footer from "./Footer";
import Button from "components/Button";

const Home = ({ className }) => {
  let params = useParams();

  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState([]);
  const [images, setImages] = useState([]);
  const [profileWeb, setProfileWeb] = useState([]);
  const htmlTag = document.querySelector("html");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    var myHeaders = new Headers();
    myHeaders.append("_web", params.name);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://project.inagata.com/flix/public/api/index-detail-info",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        setLoading(true);
        setProfileWeb(result.data.Profile_web);
        setCard(result.data.Menu_info);
        setImages(result.data.Multiple_info);
        console.log(result, "get data");
      })
      .catch((error) => console.log("error", error));
  };

  console.log(profileWeb, "profile");
  console.log(images, "images");
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      className={`${className} min-h-[100vh] bg-white dark:bg-black`}
    >
      {loading === false
        ? null
        : profileWeb.tema === "gelap"
        ? htmlTag.classList.add("dark")
        : htmlTag.classList.add("light")}

      {/* contents */}
      <div className="px-5 pb-5 pt-1">
        <Link
          to={`${loading === false ? null : profileWeb.set_path_web}`}
          className="my-3 flex gap-2 items-center justify-center"
        >
          <img
            src={loading === false ? null : profileWeb.ikon}
            alt="icon"
            className="w-[52.59px]"
          />
          <h3 className="font-bold dark:text-white">
            {loading === false ? null : profileWeb.nama_web}
          </h3>
        </Link>

        {/* filler */}
        <div className="grid grid-cols-1 gap-6 my-5">
          {loading === false
            ? null
            : card.map((item, index) => {
                return (
                  <Link
                    to={`/${params.name}/detail-info`}
                    className="flex items-center justify-between gap-8 dark:border-b"
                    key={`card info ${index}`}
                  >
                    <div className="w-[55%]">
                      <h3 className="font-bold dark:text-white">
                        {item.judul}
                      </h3>

                      <p className="font-normal text-[11px] mt-2 dark:text-white pb-3">
                        {item.deskripsi}
                      </p>
                    </div>
                    <div
                      className="w-[110px] h-[110px] rounded-lg bg-center bg-cover bg-no-repeat"
                      style={{ backgroundImage: `url(${SuperCar})` }}
                    ></div>
                  </Link>
                );
              })}
        </div>

        {/* footer */}
        <Footer />
      </div>

      {/* Navigation */}
      <NavHome className={`text-sm p-6`} />

      {/* Buttons */}
      <div className="px-5 pb-5 pt-3 dark:bg-[#282828]">
        <Link to={"/"}>
          <Button type={"button"}>Simpan dan Publish</Button>
        </Link>

        <Link to={"/settings"}>
          <Button
            type={"button"}
            btnStyle={"btn-primary-inverted"}
            className={"mt-4"}
          >
            Kembali Edit
          </Button>
        </Link>
      </div>
    </motion.div>
  );
};

export default Home;
